import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PageContainer from '../UIElements/Containers';
import { deleteWorkOrderNumberByWorksite, editWorkOrderNumberByWorksite, postWorkOrderNumberByWorksite, resetDeleteWorkOrderNumberData, resetDeleteWorkOrderNumberErrorData, resetEditWorkOrderNumberData, resetEditWorkOrderNumberErrorData, resetPostWorkOrderNumberData, resetPostWorkOrderNumberErrorData } from '../../api-actions/workSiteDataAction';
import { getWorksiteWorkOrderNumbers, postWorkOrderNumberUsingCsv, resetPostWorkOrderNumberUsingCsvData, resetPostWorkOrderNumberUsingCsvError, resetWorkSiteWorkOrderNumberErrorData } from '../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import { Link } from 'react-router-dom';
import { FiDownload, FiShare } from 'react-icons/fi';
import Loader from '../Loader/Loader';
import NoData from '../NoData/NoData';
import { nullToEmpty } from '../../utils/csvExport';
import { calculateTotalCheckIns, calculateTotalCheckOuts, calculateTotalWorkers, calculateTotalWorkHours } from '../../utils/calculateTotal';
import { RiShareBoxFill } from 'react-icons/ri';
import ButtonSpinner from '../Spinner/ButtonSpinner';


const WorkOrder = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();
  const { CSVReader } = useCSVReader();

  // CSV Reader Props
  const GREY = '#007BE8';
  const GREY_LIGHT = '#007BE8';
  const DEFAULT_REMOVE_HOVER_COLOR = '#EA2C54';
  const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
  const GREY_DIM = '#007BE8';


  const worksiteId = useSelector((state) => state.AdminAuthData.userId);
  const postWorkOrderNumbersUsingCsvData = useSelector((state) => state.WorkSiteData.postWorkSiteWorkOrderNumbersData);
  const postWorkOrderNumbersUsingCsvError = useSelector((state) => state.WorkSiteData.postWorkSiteWorkOrderNumbersError);

  const [viewData, setViewData] = useState(false);
  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);


  const workOrderNumbersData = useSelector((state) => state.WorkSiteData.getWorksiteWorkOrderNumbersData);
  const workOrderNumbersError = useSelector((state) => state.WorkSiteData.getWorksiteWorkOrderNumbersError);
  const postWorkOrderNumberData = useSelector((state) => state.WorkSiteData.postWorkOrderNumberData);
  const postWorkOrderNumberError = useSelector((state) => state.WorkSiteData.postWorkOrderNumberError);
  const editWorkOrderNumberData = useSelector((state) => state.WorkSiteData.editWorkOrderNumberData);
  const editWorkOrderNumberError = useSelector((state) => state.WorkSiteData.editWorkOrderNumberError);
  const deleteWorkOrderNumberData = useSelector((state) => state.WorkSiteData.deleteWorkOrderNumberData);
  const deleteWorkOrderNumberError = useSelector((state) => state.WorkSiteData.deleteWorkOrderNumberError);

  const [workOrderNumberWithIndex, setWorkOrderNumberWithIndex] = useState([]);
  const [workOrderNumber, setWorkOrderNumber] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentWorkOrderNumberId, setCurrentWorkOrderNumberId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [exportWorkOrderNumbersData, setExportWorkOrderNumbersData] = useState();
  const [workOrderLoader, setWorkOrderLoader] = useState(false)

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpVisibleForBulkImport, setSlideUpVisibleForBulkImport] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");

  // CSV
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
  const [bulkImportFile, setBulkImportFile] = useState('')

  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);


  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("500");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  // GET actions
  useEffect(() => {
    setIsLoading(true)
    if (worksiteId) {
      dispatch(getWorksiteWorkOrderNumbers(jwtToken, worksiteId));
    }
  }, [worksiteId])

  useEffect(() => {
    const data = workOrderNumbersData.map((workOrder, i) => (
      { ...workOrder, index: i + 1 }
    ))
    setWorkOrderNumberWithIndex(data)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [workOrderNumbersData]);

  useEffect(() => {
    if (workOrderNumbersError && Object.keys(workOrderNumbersError)?.length > 0) {
      setWorkOrderLoader(false)
      dispatch(resetWorkSiteWorkOrderNumberErrorData());
      setIsLoading(false);
    }
  }, [workOrderNumbersError]);

  // POST Actions
  useEffect(() => {
    if (postWorkOrderNumberData && Object.keys(postWorkOrderNumberData)?.length > 0) {
      setWorkOrderNumber('')
      setWorkOrderLoader(false)
      toast.success('Work order number added successfully')
      setSlideUpVisible(false)
      dispatch(getWorksiteWorkOrderNumbers(jwtToken, worksiteId));
      dispatch(resetPostWorkOrderNumberData())
    }
  }, [postWorkOrderNumberData])

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true);
  };

  useEffect(() => {
    if (postWorkOrderNumberError && Object.keys(postWorkOrderNumberError)?.length > 0) {
      toast.error(postWorkOrderNumberError.message)
      dispatch(resetPostWorkOrderNumberErrorData());
    }
  }, [postWorkOrderNumberError]);

  // PATCH Actions
  useEffect(() => {
    if (editWorkOrderNumberData && Object.keys(editWorkOrderNumberData)?.length > 0) {
      setWorkOrderNumber('');
      toast.success('WorkOrder number updated successfully');
      setSlideUpVisible(false);
      setWorkOrderLoader(false)
      dispatch(getWorksiteWorkOrderNumbers(jwtToken, worksiteId));
      dispatch(resetEditWorkOrderNumberData());
    }
  }, [editWorkOrderNumberData]);

  useEffect(() => {
    if (editWorkOrderNumberError && Object.keys(editWorkOrderNumberError)?.length > 0) {
      toast.error(editWorkOrderNumberError.message);
      setWorkOrderLoader(false)
      dispatch(resetEditWorkOrderNumberErrorData());
    }
  }, [editWorkOrderNumberError]);

  // DELETE Actions
  useEffect(() => {
    if (deleteWorkOrderNumberData && Object.keys(deleteWorkOrderNumberData)?.length > 0) {
      toast.success('WorkOrder number deleted successfully');
      dispatch(getWorksiteWorkOrderNumbers(jwtToken, worksiteId));
      dispatch(resetDeleteWorkOrderNumberData());
    }
  }, [deleteWorkOrderNumberData]);

  useEffect(() => {
    if (deleteWorkOrderNumberError && Object.keys(deleteWorkOrderNumberError)?.length > 0) {
      toast.error(deleteWorkOrderNumberError.message);
      dispatch(resetDeleteWorkOrderNumberErrorData());
    }
  }, [deleteWorkOrderNumberError]);

  useEffect(() => {
    if (postWorkOrderNumbersUsingCsvData && Object.keys(postWorkOrderNumbersUsingCsvData)?.length > 0) {
      setWorkOrderLoader(false)
      setBulkImportFile('')
      toast.success('Work order numbers added successfully')
      setSlideUpVisibleForBulkImport(false)
      dispatch(getWorksiteWorkOrderNumbers(jwtToken, worksiteId));
      dispatch(resetPostWorkOrderNumberUsingCsvData())
    }
  }, [postWorkOrderNumbersUsingCsvData])

  useEffect(() => {
    if (postWorkOrderNumbersUsingCsvError && Object.keys(postWorkOrderNumbersUsingCsvError)?.length > 0) {
      setWorkOrderLoader(false)
      toast.error(postWorkOrderNumbersUsingCsvError.message)
      dispatch(resetPostWorkOrderNumberUsingCsvError());
    }
  }, [postWorkOrderNumbersUsingCsvError]);

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value === '') {
      setWorkOrderNumberWithIndex(workOrderNumbersData.map((record, i) => ({ ...record, index: i + 1 })));
    } else {
      const filteredData = workOrderNumbersData.filter(data => {
        return (
          data.workOrderNumber?.toLowerCase().includes(value.toLowerCase())
        );
      }).map((record, i) => ({ ...record, index: i + 1 }));
      setWorkOrderNumberWithIndex(filteredData);
    }
  };

  const handleInputChange = (e) => {
    setWorkOrderNumber(e.target.value)
  }

  const handleAddOrEdit = () => {
    const workOrderNumberRegex = /^\d+$/;
    if (workOrderNumber === '' || !workOrderNumberRegex.test(workOrderNumber)) {
      return toast.error('Please enter a valid work order number');
    }
    if (isEditMode) {
      setWorkOrderLoader(true)
      dispatch(editWorkOrderNumberByWorksite(jwtToken, currentWorkOrderNumberId, { worksiteId, workOrderNumber }));
    } else {
      setWorkOrderLoader(true)
      dispatch(postWorkOrderNumberByWorksite(jwtToken, { worksiteId, workOrderNumber }));
    }
  };


  const handleAddClick = () => {
    setIsEditMode(false);
    setWorkOrderNumber('');
    setSlideUpVisible(true);
  }

  const handleEditClick = (row) => {
    setIsEditMode(true)
    setCurrentWorkOrderNumberId(row.id)
    setSlideUpVisible(true)
    setWorkOrderNumber(row.workOrderNumber)
  }

  const handleDeleteClick = (row) => {
    setCurrentWorkOrderNumberId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleDelete = () => {
    if (currentWorkOrderNumberId) {
      dispatch(deleteWorkOrderNumberByWorksite(jwtToken, currentWorkOrderNumberId));
      setSlideUpVisibleForDelete(false)
    }
  }

  const handleDownloadSampleFile = () => {
    const sampleFileUrl = '/assets/csv/work-order-numbers-file.csv';
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'work-order-numbers-file.csv';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleBulkImportUpload = () => {
    dispatch(postWorkOrderNumberUsingCsv(jwtToken, { worksiteId: worksiteId, csvData: bulkImportFile.data.flatMap(item => item) }))
  }

  const handleBulkImport = () => {
    setSlideUpVisibleForBulkImport(true)
  }


  const tableColumns = [
    {
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // formatter: (cell, row, rowIndex) => rowIndex + 1,
      formatter: (cell, row, rowIndex) => {
        const rowNumber = (currentPage - 1) * sizePerPage + rowIndex + 1; // Adjust row number based on current page
        return rowNumber;
      },
      csvExport: false,
      sortable: false,
      style: { width: '50px' },
    },
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row) => {
        return (
          <Link to={`/worksite/work-order/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
      sort: true,
      sortFunc: (a, b, order) => {
        const workOrderA = BigInt(a.replace(/\D/g, ''));
        const workOrderB = BigInt(b.replace(/\D/g, ''));

        if (order === 'asc') {
          return workOrderA > workOrderB ? 1 : -1;
        }
        if (order === 'desc') {
          return workOrderA < workOrderB ? 1 : -1;
        }
      },
    },
    {
      dataField: 'totalWorkHours',
      text: 'Total Working Hours',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        // Helper function to convert formatted string to total minutes
        const convertToMinutes = (str) => {
          const match = str.match(/(\d+)Hr\s*(\d+)Min/);
          if (match) {
            return parseInt(match[1]) * 60 + parseInt(match[2]);
          }
          return 0; // or any other default value
        };

        const aValue = convertToMinutes(a);
        const bValue = convertToMinutes(b);

        if (order === 'asc') {
          return aValue - bValue; // Ascending order
        }

        return bValue - aValue; // Descending order
      },
    },
    {
      dataField: 'totalWorkers',
      text: 'Workers',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
    },
    {
      dataField: 'totalCheckIns',
      text: 'Check Ins',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
    },
    {
      dataField: 'totalCheckOuts',
      text: 'Check Outs',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
          });
        } else {
          return '';
        }
      },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        if (order === 'asc') {
          return dateA - dateB;
        }
        return dateB - dateA;
      }
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button
            style={{ backgroundColor: 'green', borderColor: 'green' }}
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleViewClick(row)}
          >
            <i className="pg-icon"><RiShareBoxFill color='white' /></i>
          </Button>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];


  // CSV Styles
  const styles = {
    zone: {
      alignItems: 'center',
      border: `2px dashed ${GREY}`,
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      padding: 20,
    },
    file: {
      background: 'linear-gradient(to bottom, #007BE8, #007BE8)',
      borderRadius: 20,
      display: 'flex',
      height: 100,
      width: 300,
      position: 'relative',
      zIndex: 10,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    info: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
    },
    size: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      marginBottom: '0.5em',
      justifyContent: 'center',
      display: 'flex',
      color: 'fff',
    },
    name: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      fontSize: 12,
      marginBottom: '0.5em',
      color: '#fff',
    },
    progressBar: {
      bottom: 14,
      position: 'absolute',
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10,
      color: '#fff'
    },
    zoneHover: {
      borderColor: GREY_DIM,
    },
    default: {
      borderColor: GREY,
    },
    remove: {
      height: 23,
      position: 'absolute',
      right: 6,
      top: 6,
      width: 23,
    },
  };

  useEffect(() => {
    setExportWorkOrderNumbersData(nullToEmpty(workOrderNumberWithIndex))
  }, [workOrderNumberWithIndex])

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  // const options = {
  //   sizePerPageRenderer
  // };

  const options = {
    page: currentPage, // Ensure currentPage is used
    sizePerPage: sizePerPage, // Set the current size per page
    onSizePerPageChange: (sizePerPage, page) => {
      setSizePerPage(sizePerPage);
      setCurrentPage(page); // Update the page when size changes
    },
    onPageChange: (page) => setCurrentPage(page),
    sizePerPageRenderer,
  };

  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between">
                <div className="d-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-between mt-md-0 mb-1">
                  <h3 class="text-primary no-margin text-uppercase">work orders</h3>
                  <div className='row gap-3 flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-between mt-md-0'>
                    <div className="m-r-10">
                      <button aria-label="" className="btn btn-primary btn-lg mt-2 px-4" type="button" onClick={handleAddClick}>
                        + Add Work Order
                      </button>
                    </div>
                    <div className='m-r-10'>
                      <button aria-label="" className="btn btn-secondary btn-lg mt-2 mb-md-0" type="submit" onClick={handleBulkImport}>
                        <i className="pg-icon m-r-10"><FiDownload size={'15'} /></i> Bulk Import
                      </button>
                    </div>
                    <div className="pr-2 mt-2">
                      {/* {csvPropsRef?.current && (
                        <ExportCSVButton {...csvPropsRef?.current}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Work Order CSV</ExportCSVButton>
                      )} */}
                      <ExportCSVButton
                        {...csvPropsRef?.current}
                        disabled={!workOrderNumberWithIndex?.length}
                        className={workOrderNumberWithIndex?.length ? "btn-enabled" : "btn-disabled"}
                      >
                        <i className="pg-icon m-r-10"><FiShare size={'15'} /></i>
                        Export Work Order CSV
                      </ExportCSVButton>
                    </div>
                  </div>
                </div>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT btn-group w-100">

                      {/* <div className='row w-100 mb-1 align-items-center px-2'>
                        <div className='col-12 col-md-6 col-lg-3 px-0'>
                          <div className="input-group m-r-15 w-auto">
                            <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search </i></span>
                            <input type="text" className="form-control py-2" placeholder="Search by Work Order No" value={searchValue} onChange={handleSearchChange} />
                          </div>
                        </div>
                      </div> */}


                      {/* <div className="input-group m-r-15 w-auto">
                        <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                        <input type="text" className="form-control p-l-10" placeholder="Search" value={searchValue} onChange={handleSearchChange} />
                      </div>

                      <button aria-label="" className="btn btn-primary btn-lg px-4" type="submit" onClick={handleAddClick}>
                        + Add
                      </button>

                      <button aria-label="" className="btn btn-secondary btn-lg px-4" type="submit" onClick={handleBulkImport}>
                        Bulk Import
                      </button> */}

                      {slideUpVisibleForBulkImport ?
                        <SlideUpModal visible={slideUpVisibleForBulkImport} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForBulkImport(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Upload Work Order Numbers</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForBulkImport(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>

                              <div className="modal-body px-4 px-md-5 py-4">
                                <CSVReader
                                  onUploadAccepted={(results) => {
                                    setBulkImportFile(results)
                                  }}
                                  onDragOver={(event) => {
                                    event.preventDefault();
                                    setZoneHover(true);
                                  }}
                                  onDragLeave={(event) => {
                                    event.preventDefault();
                                    setZoneHover(false);
                                  }}
                                >
                                  {({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps,
                                    Remove,
                                  }) => (
                                    <>
                                      <div
                                        {...getRootProps()}
                                        style={Object.assign(
                                          {},
                                          styles.zone,
                                          zoneHover && styles.zoneHover
                                        )}
                                      >
                                        {acceptedFile ? (
                                          <>
                                            <div style={styles.file}>
                                              <div style={styles.info}>
                                                {/* <span style={styles.size}>
                                                  {formatFileSize(acceptedFile.size)}
                                                </span> */}
                                                <span style={styles.name}>{acceptedFile.name}</span>
                                              </div>
                                              <div style={styles.progressBar}>
                                                <ProgressBar />
                                              </div>
                                              <div
                                                {...getRemoveFileProps()}
                                                style={styles.remove}
                                                onMouseOver={(event) => {
                                                  event.preventDefault();
                                                  setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                                                }}
                                                onMouseOut={(event) => {
                                                  event.preventDefault();
                                                  setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                                                }}
                                              >
                                                <Remove color={removeHoverColor} />
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          'Drop CSV file here or click to upload'
                                        )}
                                      </div>
                                    </>
                                  )}
                                </CSVReader>

                                <div className="row mt-4">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={handleDownloadSampleFile}>
                                      Sample File
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" disabled={bulkImportFile === ''} type="button" className="btn btn-primary btn-block" onClick={handleBulkImportUpload}>
                                      Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}
                      {slideUpVisibleForViewData ?
                        <SlideUpModal visible={slideUpVisibleForViewData} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForViewData(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Manage Work Order Details</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForViewData(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body py-3 h100vh">
                                <div className="row">
                                  <div className="col-md-6">
                                    {/* <p><strong>ID:</strong> {viewData.id}</p> */}

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Work Order Number</p>
                                          {viewData.workOrderNumber ?
                                            <p class="bold mb-0">{viewData.workOrderNumber}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Total Working Hours</p>
                                          {viewData.totalWorkHours ?
                                            <p class="bold mb-0">{viewData.totalWorkHours}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>



                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check In</p>
                                          {viewData.totalCheckIns ?
                                            <p class="bold mb-0">{viewData.totalCheckIns}</p> :
                                            <p class="bold mb-0 ml-1">{"0"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>


                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Created At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.createdAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="col-md-6">

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Workers</p>
                                          {viewData.totalWorkers ?
                                            <p class="bold mb-0">{viewData.totalWorkers}</p> :
                                            <p class="bold mb-0 ml-1">{"0"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Check Out</p>
                                          {viewData.totalCheckOuts ?
                                            <p class="bold mb-0">{viewData.totalCheckOuts}</p> :
                                            <p class="bold mb-0 ml-1">{"0"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Updated At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.updatedAt)}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal>
                        : null}

                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body mt-2">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block mt-2 mt-md-0" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>{isEditMode ? 'Edit Work Order Number' : 'Add Work Order Number'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body px-4 px-md-5 py-4">
                                <form role="form">
                                  <div class="input-group">
                                    <input type="text" className="form-control" placeholder='Enter Work Order Number' value={workOrderNumber} onChange={handleInputChange} />

                                  </div>

                                </form>
                              </div>
                              <div className='modal-footer'>
                                {!workOrderLoader ?
                                  <button aria-label="" type="button" className="btn btn-primary" onClick={handleAddOrEdit}>
                                    {isEditMode ? 'Update' : '+ Add'}
                                  </button>
                                  :
                                  <button aria-label="" type="button" className="btn btn-primary" >
                                    <ButtonSpinner />
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap justify-content-start'>
                  <div className="input-group col-lg-3 col-md-6 col-sm-6 col-xs-12 p-l-0 mb-3">
                    <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search </i></span>
                    <input type="text" className="form-control py-2" placeholder="Search by Work Order No" value={searchValue} onChange={handleSearchChange} />
                  </div>
                </div>

                {/* <div className='row w-100 mb-1 align-items-center px-2'>
                  <div className='col-12 col-md-6 col-lg-3 px-0'>
                    <div className="input-group m-r-15 w-auto">
                      <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search </i></span>
                      <input type="text" className="form-control py-2" placeholder="Search by Work Order No" value={searchValue} onChange={handleSearchChange} />
                    </div>
                  </div>
                </div> */}

              </div>
              {!isLoading ? <div className="card-body custable">
                {workOrderNumberWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportWorkOrderNumbersData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'WorkOrders.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default WorkOrder;
